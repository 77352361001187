<template>
  <v-container
    fluid
    class="product-slider"
    v-if="mode === 'banner' || (products && products.length > 0)"
  >
    <div class="d-flex justify-center" v-if="title">
      <div
        class="text-h4 text-center primary--text text--lighten-1 font-weight-bold my-6"
        v-html="title"
      ></div>
    </div>
    <div>
      <ProposalImage v-if="mode == 'banner' && hasImage" :proposal="proposal" />
      <swiper
        :options="swiperOption"
        ref="swiperRef"
        v-if="products.length > 0"
      >
        <swiper-slide v-for="product in products" :key="product.productId">
          <ProductCard v-bind:product="product" />
        </swiper-slide>
      </swiper>
    </div>
    <div
      class="swiper-pagination"
      :class="paginationClass"
      slot="pagination"
      v-if="products.length > 1"
    ></div>
  </v-container>
</template>

<script>
import ProductService from "~/service/productService";
import ProductCard from "@/components/product/ProductCard";
import ProposalImage from "@/components/proposal/ProposalImage";
import get from "lodash/get";

export default {
  name: "ProductListSlider",
  props: {
    categoryId: { type: Number, required: false },
    productId: { type: Number, required: false },
    layout: { type: Number, required: false },
    shuffle: { type: Boolean, required: false, default: false },
    promo: { type: Boolean, required: false, default: false },
    limit: { type: Number, required: false, default: 8 },
    title: { type: String, required: false },
    showArrows: { type: Boolean, default: true },
    proposal: { type: Object, required: false },
    paginationClass: { type: String, required: false },
    "web-component": { type: Boolean, required: false, default: false }
  },
  components: { ProductCard, ProposalImage },
  data() {
    return {
      products: [],
      swiperOption: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 24,
        watchOverflow: true,
        pagination: {
          el: "." + this.paginationClass,
          clickable: true
        },
        navigation: {
          prevEl: `#product-list-slider-prev-${this.paginationClass}`,
          nextEl: `#product-list-slider-next-${this.paginationClass}`
        },
        breakpoints: {
          320: {
            slidesPerView: 1.5,
            centeredSlides: true
          },
          600: {
            slidesPerView: 1.5,
            slidesPerGroup: 1.5,
            centeredSlides: true
          },
          960: {
            slidesPerView: 4,
            slidesPerGroup: 4,
            centeredSlides: false
          },
          1280: {
            slidesPerView: 5,
            slidesPerGroup: 5,
            centeredSlides: false
          },
          1590: {
            slidesPerView: 5,
            slidesPerGroup: 5,
            centeredSlides: false
          }
        }
      }
    };
  },
  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    if (this.mode == "card") {
      this.fetchProducts();
    }
  },
  computed: {
    hasImage() {
      return (
        this.proposal &&
        this.proposal.img != "https://laspesaonline.eurospin.it"
      );
    },
    mode() {
      return get(this.proposal, "metaData.category_proposal_type.MODE", "card");
    }
  },
  methods: {
    async fetchProducts() {
      if (this.proposal) {
        let response = await ProductService.search({
          parent_category_id: this.proposal.categoryProposedId,
          page_size: 12
        });
        this.products = response.products;
      } else {
        let response = await ProductService.adv({
          layout: this.layout,
          product_id: this.productId,
          category_id: this.categoryId,
          limit: this.limit,
          shuffle: this.shuffle,
          promo: this.promo
        });
        this.products = response.products;
      }
    }
  }
};
</script>
